import React, { useEffect, useState } from "react"
import "./css/winners.css"
import ReactModal from "react-modal"
import { getRanking } from "../services/user-api"
import Confetti from "react-confetti"
import { useTranslation } from "react-i18next"

ReactModal.setAppElement("#root")

const Winners = ({ isOpen, closeModal }) => {
  const { t } = useTranslation("global")
  const [users, setUsers] = useState([])

  useEffect(() => {
    getRanking()
      .then((res) => {
        setUsers(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
  }

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
        contentLabel="Winners Dialog"
        overlayClassName="winner-dialog-overlay"
        className="winner_dialog"
      >
        <div className="winners_page">
          <section className="header_container">
            <div className="title_box">
              <img src="/images/crown.png" alt="Crown" />
              <div className="title">Top 10 Winners</div>
            </div>
            <button className="close_btn" onClick={closeModal}>
              <i className="fas fa-xmark"></i>
            </button>
          </section>
          <section>
            <table className="table">
              <thead className="thead">
                <tr>
                  <th scope="col">{t("Player")}</th>
                  <th scope="col">{t("Rank")}</th>
                  <th scope="col">{t("Time Spent")}</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 &&
                  users.map((user, index) => {
                    return (
                      <tr key={user.id}>
                        <td>
                          <div className="player_name">
                            <img src="/images/profile.png" alt="profile" />
                            <div>{user.mobileNumber}</div>
                          </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{formatTime(user.totalTime)}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </section>
        </div>
        <Confetti
          width={
            document.getElementsByClassName("winners_page")[0]?.clientWidth
          }
          height={
            document.getElementsByClassName("winners_page")[0]?.clientHeight
          }
        />
      </ReactModal>
    </>
  )
}

export default Winners
