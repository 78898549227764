import React, { useState,useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import './css/style.css';
import './css/login1.css';
import { unsubApi } from '../services/user-api';
import {serviceId,Loader} from "../utils/constant";
import { useTranslation } from "react-i18next";
import Select from "react-select"
import { LanguageContext } from "../components/LanguageContext"

const Unsub = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({message: "",error: false});
    const [flow, setFlow] = useState('');
    const [unsub, setUnsub] = useState(false);
    const { t } = useTranslation("global");
    

    const unsubFunc = async() => {
        setLoading(true);
        await unsubApi({action:2,serviceId:serviceId,mode:'WAP'}).then((res) => {
            setMessage({message:t("portalText.Unsub Msg"),error: false})
            setUnsub(true); 
            localStorage.clear(); 
        }).catch((err) => {
            console.log(err)
            setMessage({message: err.message,error: true})
        }).finally(() => setLoading(false))
       
      };
    const redirect = async(input) => {
       // console.log(input);
        setFlow(input);
    }
      const { language, changeLanguage } = useContext(LanguageContext); 
      const handleChange = (selectedOption) => {
        changeLanguage(selectedOption.value)
      }
      const languages = [    
        { value: "fr", label: "French" },
        { value: "en", label: "English" },
        //{ value: "pr", label: "Portuguese" },
      ]
      const selectedOption = languages.find((option) => option.value === language)
      const customStyles = {
        control: (provided) => ({
          ...provided,
          backgroundColor: "#d80000",
          fontSize: "18px",
          fontWeight: 700,
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "10px",
          border: "none",
          outline: "none",
          margin: "0 auto",
          minHeight: "40px",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#d80000" : "#fff",
          color: state.isSelected ? "#fff" : "#000",
          cursor: "pointer",
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: "10px",
          marginTop: "5px",
        }),
      }
      

    return (
        
            <div className='home'>                 
                <div className="maine-div">
                    <div className="container-fluid box-wrapper login"> 
                    <div className='pt-5'>
                        <div className="row align-items-center">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="logo d-flex">
                                    {/*<img src="/images/uv_games_logo.png" alt="UVGames" />*/}
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="pt-2">
                                        <Select
                                            menuPlacement="top"
                                            value={selectedOption}
                                            options={languages}
                                            onChange={handleChange}
                                            styles={customStyles}
                                            placeholder="Select Language"
                                        />        
                                </div>
                            </div>  
                        </div>
                    </div>                                    
                       
                        <div className="login-header pb-4">
                            <div className="box-wrapper mx-auto text-center position-relative"> 
                                <span className="modal-level-title game-title">{t("portalText.desc")}</span>
                            </div>
                        </div>
                        
                        {flow==="UNSUB"?(
                            <div className="login-info">                            
                                <div className="login-thumbnail2">
                                    <img src="/images/login_img.png" alt="UVGames" />
                                </div>                                                
                                    
                                {message.message && <div className="mb-3"><p className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</p></div>}
                                {unsub?<></>:
                                <>
                                    <h3 className="login-title mb-3 mt-3 text-center">{t("portalText.Unsub Desc")}</h3>
                            
                                    {loading ? <Loader />:
                                    <>
                                        <button className="btn btn-primary btn-pm-custom" type="button" onClick={unsubFunc}>{t("portalText.Unsub Button")}</button>
                                        <div className="resend_otp">
                                                <NavLink onClick={() => {redirect('')}} className="send_otp_link" > &lt;&lt;&nbsp;{t("portalText.Back")}</NavLink>
                                        </div>
                                    </>  
                                    }  
                                </>                            
                                }
                            </div>
                        ):flow==="CONTACTUS"?(
                            <div className="login-info">
                                 <a onClick={() => {redirect('')}} className="send_otp_link"> &lt;&lt;&nbsp;{t("portalText.Back")}</a><br/><br/>
                                
                                    <p><strong><u>{t("portalText.ContactUs Header")}</u></strong></p>
                                    <div class="home-profile contact-us">
                                        <p>{t("portalText.ContactUs Desc")}.</p>
                                        <a href="tel:+23788888888888" class="info"> <img src="/images/call.png" /> +237xxxxxxxxxx</a>
                                        <a href="mailto:Contact@domain.com" class="info"><img src="/images/mail.png" /> Contact@domain.com</a>                                        
                                    </div> 
                            </div>
                        ):flow==="RULES"?(
                            <div className="login-info"> 
                                <a onClick={() => {redirect('')}} className="send_otp_link" > &lt;&lt;&nbsp;{t("portalText.Back")}</a><br/><br/>
                                {language==="fr"?(<>
                                <p><strong><u>UVGAMING - Règles et politiques</u></strong></p>
                                <ol start="1"><li><strong>Abonnement</strong></li>
                                <p>Le client doit s'abonner à UVGAMING via WAP/WEB, USSD ou Max it CM</p></ol>
                                <ol start="2"><li><strong>Critères gagnants</strong></li>
                                <p>Les abonnés qui passeront le plus de temps à jouer sur UVGaming verront leur temps affiché sur un tableau de classement. Les 5 meilleurs joueurs ayant cumulé le plus d'heures de jeu seront récompensés.
                                <br/>Avoir un compte Orange Money actif est dûment identifiés et à jour
                                </p>
                                </ol>
                                <ol start="3">
                                <li><strong>Gratifications mensuelles</strong></li> 
                                <p>À la fin de chaque mois, Les 5 meilleurs joueurs ayant cumulé le plus d'heures de jeu seront récompensés. Les récompenses sont attribuées de la manière suivante :</p>
                                
                                <ul>
                                <li><strong>1ère Place : </strong>le premier abonné sur le tableau de classement reçoit 10 000 francs(Unité Orange Money)</li>
                                <li><strong>De la 2e à la 5e place : </strong>les abonnés classés de la 2e à la 5ème place reçoivent chacun 3 000 francs (Unité de valeur Orange Money)</li>
                                </ul>
                                </ol>
                                </>
                                ):(<>
                                <p><strong><u>UVGAMING- Rules and Policies</u></strong></p>
                                <ol start="1"><li><strong>Subscription</strong></li>
                                <p>The customer must subscribe to UVGAMING via WAP/WEB, USSD or Max it CM</p></ol>
                                <ol start="2"><li><strong> Winning criteria</strong></li>
                                <p>Subscribers who spend the most time playing on UVGAMING will see their time displayed on a leaderboard. The 5 best players who have accumulated the most hours of play will be rewarded.
                                <br/>
                                Have an active Orange Money account that is duly identified and up to date</p>
                                </ol>
                                <ol start="3">
                                <li><strong> Monthly bonuses</strong></li> 
                                <p>At the end of each month, the 5 best players who have accumulated the most hours of play will be rewarded. Rewards are awarded as follows:</p>
                                
                                <ul>
                                <li><strong>1st Place:</strong> The first subscriber on the ranking table receives 10,000 francs (Orange Money Unit)</li>
                                <li><strong>2nd to 5th Place:</strong> subscribers ranked 2nd to 5th place each receive 3,000 francs (Orange Money Value Unit)</li>
                                </ul>
                                </ol>
                                </>
                            )}
                            </div>
                        ):(
                           <div className="login-info"> 
                                <div className="login-thumbnail2">
                                    <img src="/images/login_img.png" alt="UVGames" />
                                </div>      
                               <button className="btn btn-primary btn-pm-red" type="button" onClick={() => {redirect('UNSUB')}}>{t("portalText.Unsub Button")}</button>
                               <button className="btn btn-primary btn-pm-red" type="button" onClick={() => {redirect('RULES')}}>{t("portalText.Rules")}</button>
                               <button className="btn btn-primary btn-pm-red" type="button" onClick={() => {redirect('CONTACTUS')}}>{t("portalText.ContactUs")}</button>
                               <div className="resend_otp"><NavLink to='/' className="send_otp_link" > &lt;&lt;&nbsp;{t("portalText.Back")}</NavLink></div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
       
    )
}

export default Unsub