import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import global_en from "./locales/en/global.json"
import global_fr from "./locales/fr/global.json"
import global_pr from "./locales/pr/global.json"

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false },
    lng: "fr", // Default language
    fallback: "fr",
    resources: {
      en: { global: global_en },
      fr: { global: global_fr },
      pr: { global: global_pr },
    },
  })

export default i18n
